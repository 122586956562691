import { render, staticRenderFns } from "./LessonContentWorksheet.vue?vue&type=template&id=484c90ac&scoped=true"
import script from "./LessonContentWorksheet.vue?vue&type=script&lang=js"
export * from "./LessonContentWorksheet.vue?vue&type=script&lang=js"
import style0 from "./LessonContentWorksheet.vue?vue&type=style&index=0&id=484c90ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484c90ac",
  null
  
)

export default component.exports