<template>
  <div>
    <div class="d-flex align-items-center justify-content-end flex-wrap">
      <div v-if="text" v-html="renderMd(text)" class="flex-grow-1 mr-2"></div>
      <b-button
        v-if="document.download_link"
        @click="fireDownloadEvent(document.download_link)"
        :href="document.download_link"
        target="_blank"
        class="download-button"
        variant="outline-blue-button"
      >
        Stiahnuť
      </b-button>
    </div>
    <iframe
      v-if="document.link"
      :src="document.link + '#toolbar=0&navpanes=0'"
      class="mt-3"
    ></iframe>
    <div class="info-callout p-3">
      <img src="/infoIcon.png" />
      <div class="paragraph font-weight-bold">
        Ak vám nejde načítať dokument alebo kvíz, vyskúšajte niektoré z tipov:

        <ul>
          <li>Refresh stránky</li>
          <li>Prekliknutie sa na iné stránky a naspäť</li>
          <li>Odhlásenie/prihlásenie</li>
        </ul>

        V prípade pretrvávajúcich problémov nás kontaktuje na
        <a class="link" href="mailto:team@zmudri.sk"> team@zmudri.sk </a>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
    },
  },

  data() {
    return {
      md: null,
    };
  },

  async created() {
    this.md = new MarkdownIt({
      html: true,
    });
  },

  methods: {
    fireDownloadEvent(worksheetUrl) {
      this.$eventLogger.userLog("lesson.worksheet.download", {
        worksheet_url: worksheetUrl,
      });
    },
    renderMd(input) {
      return this.md.render(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.download-button {
  padding: 0.5rem 4rem;
}

.info-callout {
  font-family: "Poppins";
  max-width: 467px;
  background: #fff5d1;
  border: 1px solid #e5d8a7;
  border-radius: 4px;
  margin: 20px auto;
  display: flex;
  align-items: center;

  img {
    margin-right: 30px;
  }

  .paragraph {
    color: #998c5c;
    white-space: normal;
    margin: 0;

    a.link {
      font-weight: 700;
      color: inherit;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 20em;
  }
}
</style>
